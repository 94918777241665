import React, { useEffect, useState } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import { Helmet } from "react-helmet";
import "./cuestionario-autovaloracion.scss";
import QuestionnaireLanding from "../components/questionnaire-landing/questionnaire-landing";
import { QuestionnaireWizard } from "../components/questionnaire-wizard/questionnaire-wizard";
import { sendEvent } from '../utils/analyticsService';

export default function CuestionarioAutovaloracion({location}) {
    const [currentQuestion, setCurrentQuestion] = useState(0);

    // console.log("JES location.state.init",location.state.init);


    // if(location.state.init) {
    //     setCurrentQuestion(0);
    // }

    const handleStart = () => {
        setCurrentQuestion(1);
        sendEvent(`Comienza Cuestionario`, `Comienza Cuestionario`)
    };

    // useEffect(() => {
    //     console.log('JES useEffect de cuestionario');
    //   }, []);

    return (
        <React.Fragment>
            <SEO  title="Cuestionario de Autovaloración"/>
            <Layout pageId="questionnaire-page">
                <Helmet title={`negligenciasdeabogados.net - Autovaloración`} />
                <h1>Cuestionario de Autovaloración</h1>

                <div className="questionnaire-container">
                    {currentQuestion === 0 ? (
                        <QuestionnaireLanding onstart={handleStart} />
                    ) : (
                        <QuestionnaireWizard />
                    )}
                </div>
            </Layout>
        </React.Fragment>
    );
}
