import React, { useEffect } from "react";
import { navigate } from "gatsby";

import { useMachine } from "@xstate/react";
import {
    getQuestionnaireStateMachine,
    getQuestionData,
    getQuestionStates,
    removeUserQuestionnaire,
} from "./questionnaire-data-service";
import { Question } from "./question";
import {
    getPreviousQuestionIndex,
    navigateWithSleep,
} from "../../utils/helpers";
import { QuestionnaireProgressIndicator } from "./questionnaire-progress-indicator";
import { sendEvent } from '../../utils/analyticsService';

const questionStateMachine = getQuestionnaireStateMachine();

// console.log("JES questionStateMachine", questionStateMachine);

export const QuestionnaireWizard = () => {
    const [state, send] = useMachine(questionStateMachine);

    const handleBack = () => {
        send("go-back");
        // const previousQIndex = getPreviousQuestionIndex(state.value);
        // console.log('JES previous index-->', previousQIndex);
        // const nextState = questionStateMachine.transition(state, 'go-back');
        // // onClickFn(`${qIndex}-${responseSelected}-clicked`);
    };

    useEffect(() => {
        if (state.value === "question1") {
            removeUserQuestionnaire();
        }
        if (state.value.startsWith("deadEnd")) {
            sendEvent(
              `Cuestionario DEADEND`,
              `Cuestionario DEADEND: ${state.value}`
            );

            const chunks = state.value.split("@");
            if (chunks.length > 1) {
                navigateWithSleep(chunks[1]);
            }
        }
    });

    return (
        <div className="container">
            {!state.value.startsWith("deadEnd") && (
                <React.Fragment>
                    <QuestionnaireProgressIndicator
                        currentCount={state.value.replace("question", "")}
                        totalCount={
                            Object.keys(getQuestionStates(questionStateMachine))
                                .length
                        }
                    />
                    <Question
                        qIndex={state.value}
                        onNextFn={send}
                        onBackFn={handleBack}
                    />
                </React.Fragment>
            )}
            {state.value.startsWith("deadEnd") && (
                <h3>Redirigiendo a resolución ...</h3>
            )}

            {/* <p style={{ clear: "both" }}>
                Current wizard state: <code>{state.value}</code>
            </p> */}
        </div>
    );
};
