import React from "react";
import "./questionnaire-progress-indicator.scss";

const renderStep = (index, currentCount) => {

    return (

        <li className={`stepper-item  ${parseInt(currentCount) === parseInt(index+1) ? 'current': ''}`} key={index}>
            {/* <a
                href={`#stepper-step-${index}`}
                title={`Step ${index}`}
            > */}
                <span>{index+1}</span>
            {/* </a> */}
        </li>
    );
};

const renderStepList = (count, currentCount) => {
    return Array.from({ length: count }).map((u, i) => i).map((index) => {
        return renderStep(index, currentCount);
    });
};
export const QuestionnaireProgressIndicator = ({
    currentCount,
    totalCount,
}) => {
    return (
        <div>
            <div className="stepper">
                <ul className="stepper-container">
                    {renderStepList(totalCount, currentCount)}
                </ul>
            </div>
        </div>
    );
};
