import React from "react";
import { button } from "./form-button.module.scss"

export default function FormButton({ children, onclick, disabled }) {
    return (
        <React.Fragment>

        <button
            className={button}
            onClick={onclick}
            disabled={disabled}
        >
            {children}
        </button>
        </React.Fragment>
    );
}
