import React, { useState } from "react";
import Recaptcha from "react-recaptcha";
import CtaButton from "../cta-button/cta-button";
import Note from "../note/note";
import { StringyWindup } from "../stringy-windup/stringy-windup";
import {
  container,
  textContent,
  buttons,
button } from "./questionnaire-landing.module.scss"
import Fade from "react-reveal/Fade";


const QuestionnaireLanding = ({ onstart }) => {
    const [status, setStatus] = useState("");

    const initialDisabled =
        process.env.NODE_ENV !== "development" ? true : false;
    const [disableStart, setDisableStart] = useState(initialDisabled);

    const callback = () => {
        // console.log("JES callback");
    };

    const renderCaptcha = () => {
        // return true ? (
        return process.env.NODE_ENV !== "development" ? (
            <Recaptcha
                sitekey="6LftMXAUAAAAAMroKcKnBzTR4AoO4-DoZN4dxBvK"
                render="explicit"
                verifyCallback={(response) => {
                    setDisableStart(false);
                }}
                onloadCallback={callback}
                hl="es"
            />
        ) : (
            ""
        );
    };

    return (
        <div className={container}>
            <StringyWindup
                text="Estás a un paso de saber si puedes reclamar a tu abogado."
                callbackFn={() => setStatus("step-1")}
            ></StringyWindup>

            <Fade left duration={2000} when={status === "step-1"}>
                <p className={textContent}>
                    Después de contestar como{" "}
                    <strong>máximo 10 preguntas</strong> podremos decirte{" "}
                    <strong>si tu reclamación es viable</strong>. En cualquier caso
                    te daremos las mejores opciones para continuar con el procedimiento.
                </p>

                <Note>
                    No requiere de la introducción de datos de caracter
                    personal.
                </Note>
                {renderCaptcha()}
                <div className={buttons}>
                    <CtaButton
                        className={button}
                        disabled={disableStart}
                        onclick={onstart}
                    >
                        Comenzar cuestionario
                    </CtaButton>
                </div>
            </Fade>
        </div>
    );
};

export default QuestionnaireLanding;
