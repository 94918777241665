import {
    getQuestionData,
    textTruncate,
    getUserQuestionnaire,
    persistResponseInUserQuestionnaire,
} from "./questionnaire-data-service";

import React, { useEffect, useState } from "react";
import FormButton from "../form-button/form-button";
import {
  questionContainer,
  title,
  listContainer,
  item,
  itemLeft,
  arrow,
  check,
  buttons,
  itemSelected,
} from "./question.module.scss"
import { faCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { getPreviousQuestionIndex } from "../../utils/helpers";
import { sendEvent } from "../../utils/analyticsService";

export const Question = ({ qIndex, onNextFn, onBackFn }) => {
    const [responseSelected, setResponseSelected] = useState("none");

    const qData = getQuestionData(qIndex);

    useEffect(() => {
        const existingUserQ = getUserQuestionnaire();
        if (existingUserQ && existingUserQ[qIndex]) {
            setResponseSelected(existingUserQ[qIndex].r);
        } else {
            setResponseSelected("none");
        }
    }, [qIndex]);

    return (
        <div className={questionContainer}>
            <h3 className={title}>{qData.text}</h3>
            {qData.responses && (
                <ul className={listContainer}>
                    {Object.entries(qData.responses).map(([rKey, value]) => {
                        const itemKey = `${qIndex}-${rKey}-clicked`;
                        return (
                            <li
                                key={rKey}
                                className={cx(item, {
                                    [itemSelected]:
                                        responseSelected === rKey,
                                })}
                                onClick={() => {
                                    setResponseSelected(rKey);
                                }}
                            >
                                <div className={itemLeft}>
                                    <FontAwesomeIcon
                                        className={arrow}
                                        icon={faArrowRight}
                                    />

                                    {value.text}
                                </div>
                                {responseSelected === rKey && (
                                    <FontAwesomeIcon
                                        className={check}
                                        icon={faCheck}
                                    />
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
            <div className={buttons}>
                <FormButton
                    onclick={() => {
                        onBackFn();
                    }}
                    disabled={qIndex === "question1"}
                >
                    Atrás
                </FormButton>
                <FormButton
                    onclick={() => {
                        // Persist
                        const qText = qData.text;
                        const rText = qData.responses[responseSelected].text;
                        persistResponseInUserQuestionnaire(
                            qIndex,
                            qText,
                            rText
                        );
                        // Analytics
                        sendEvent(
                            `Cuest. Auto Respuesta: ${qIndex}: ${responseSelected}`,
                            `${qIndex}: ${responseSelected}`,
                            { q: textTruncate(rText), r: textTruncate(qText) }
                        );
                        // State transition
                        onNextFn(`${qIndex}-${responseSelected}-clicked`);
                    }}
                    disabled={responseSelected === "none"}
                >
                    Continuar
                </FormButton>
            </div>
        </div>
    );
};
